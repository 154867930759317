.not-found {
    color: white;
    text-align: center;
    margin: 5em auto;
    width: auto;
}

.not-found h1 {
    color: #f3f3f3;
    margin-bottom: 12px;
}

.not-found h2 {
    color: #f3f3f3;
    width: auto;
    display: inline-block;
}

.not-found a {
    font-size: 28px;
    font-weight: 600;
}